export default function initSmoothScroll() {
    if ('querySelector' in document && 'addEventListener' in window && Array.prototype.forEach) {
        // Function to animate the scroll
        const smoothScroll = (anchor, duration) => {
            // Calculate how far and how fast to scroll
            const startLocation = window.pageYOffset;
            const endLocation = anchor.offsetTop;
            const distance = endLocation - startLocation;
            const increments = distance / (duration / 16);
            let stopAnimation;

            // Scroll the page by an increment, and check if it's time to stop
            const animateScroll = () => {
                window.scrollBy(0, increments);
                stopAnimation();
            };

            // Loop the animation function
            const runAnimation = setInterval(animateScroll, 16);

            // If scrolling down
            if (increments >= 0) {
                // Stop animation when you reach the anchor OR the bottom of the page
                stopAnimation = () => {
                    const travelled = window.pageYOffset;
                    if ((travelled >= (endLocation - increments)) ||
                        ((window.innerHeight + travelled) >= document.body.offsetHeight)) {
                        clearInterval(runAnimation);
                    }
                };
            } else { // If scrolling up
                // Stop animation when you reach the anchor OR the top of the page
                stopAnimation = () => {
                    const travelled = window.pageYOffset;
                    if (travelled <= (endLocation || 0)) {
                        clearInterval(runAnimation);
                    }
                };
            }
        };

        // Define smooth scroll links
        const scrollToggle = document.querySelectorAll('.scroll');

        // For each smooth scroll link
        [].forEach.call(scrollToggle, (toggle) => {
            // When the smooth scroll link is clicked
            toggle.addEventListener('click', (e) => {
                e.preventDefault();

                // Get anchor link and calculate distance from the top
                const dataID = toggle.getAttribute('href');
                const dataTarget = document.querySelector(dataID);
                const dataSpeed = toggle.getAttribute('data-speed');

                if (dataTarget) {
                    smoothScroll(dataTarget, dataSpeed || 500);
                }
            }, false);
        });
    }
}
